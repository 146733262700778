@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-dual-listbox/lib/react-dual-listbox.css';

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  overscroll-behavior: none;
}

body>#root {
  height: 100%;
}

body>#root>.App {
  height: 100%;
}

html {
  height: 100%;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* 7m-time main colors */
  --7m-blue-lighten-5: #77BCFC;
  --7m-blue: #1077D7;
  --7m-blue-darken-5: #0066C6;
  --7m-pink-lighten-5: #EEE9FF;
  --7m-pink: #d2cbe7;
  --7m-purple-lighten-5: #31149F;
  --7m-purple: #341B8E;
  --7m-purple-darken-5: #120542;
  --7m-yellow: #FFE600;
  --7m-red-lighten-5: #FE571D;
  --7m-red: #FF581D;
}
.rdl-list-box{
  width: 180px;
}